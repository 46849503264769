.rankingWrapper {
  //transform: translateX(18%);
  & > * {
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transform: translateX(#{- ($i - 1) * 30%});
        z-index: #{10 - $i};
      }
    }
  }
}
