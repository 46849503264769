// Remove this hack after updating material-ui
// details https://github.com/mui-org/material-ui/pull/26624
.dateTimePickerWrapper {
  & > * {
    &:nth-child(2) {
      & > * {
        &:nth-child(2) {
          & > button {
            bottom: 56px;
          }
        }
      }
    }
  }
}
