.wrapper {
  & > :global(.slider-picker) {
    margin-top: 10px;
    margin-bottom: 2px;
    & > div {
      &:first-child {
        display: none;
      }
      &:nth-child(2) {
        & > div {
          margin-top: 0 !important;
        }
      }
    }
  }
}
