@import '../packages/ui/src/styles/index.scss';
@import '~react-quill/dist/quill.core.css';
@import '~react-quill/dist/quill.snow.css';

.crossed {
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      #e0e0e0 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      #e0e0e0 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

strong,
b {
  font-family: 'HelveticaNowDisplay-Bold', '"Helvetica Neue"', 'sans-serif';
}

#appConfiguratorRoot {
  font-family: 'HelveticaNowDisplay-Medium', '"Helvetica Neue"', 'sans-serif';
}

.app-configurator-portal-highlighted-text {
  background-color: rgba(#ff8f3d, 0.5);
}

.lock-scroll {
  overflow: hidden;
}
