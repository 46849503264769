@font-face {
  font-family: 'HelveticaNowDisplay-Bold';
  src: url('./HelveticaNowDisplay-Bold.eot');
  src: url('./HelveticaNowDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNowDisplay-Bold.woff2') format('woff2'), url('./HelveticaNowDisplay-Bold.woff') format('woff'),
    url('./HelveticaNowDisplay-Bold.ttf') format('truetype'),
    url('./HelveticaNowDisplay-Bold.svg#./HelveticaNowDisplay-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./HelveticaNowDisplay-Regular.eot');
  src: url('./HelveticaNowDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNowDisplay-Regular.woff2') format('woff2'), url('./HelveticaNowDisplay-Regular.woff') format('woff'),
    url('./HelveticaNowDisplay-Regular.ttf') format('truetype'),
    url('./HelveticaNowDisplay-Regular.svg#./HelveticaNowDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay-Medium';
  src: url('./HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('./HelveticaNowDisplay-Medium.woff') format('woff'), url('./HelveticaNowDisplay-Medium.ttf') format('truetype'),
    url('./HelveticaNowDisplay-Medium.svg#HelveticaNowDisplay-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./HelveticaNowText-Regular.eot');
  src: url('./HelveticaNowText-Regular.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNowText-Regular.woff2') format('woff2'), url('./HelveticaNowText-Regular.woff') format('woff'),
    url('./HelveticaNowText-Regular.ttf') format('truetype'),
    url('./HelveticaNowText-Regular.svg#HelveticaNowText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Bold';
  src: url('./HelveticaNowText-Bold.eot');
  src: url('./HelveticaNowText-Bold.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNowText-Bold.woff2') format('woff2'), url('./HelveticaNowText-Bold.woff') format('woff'),
    url('./HelveticaNowText-Bold.ttf') format('truetype'),
    url('./HelveticaNowText-Bold.svg#HelveticaNowText-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Medium';
  src: url('./HelveticaNowText-Medium.eot');
  src: url('./HelveticaNowText-Medium.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNowText-Medium.woff2') format('woff2'), url('./HelveticaNowText-Medium.woff') format('woff'),
    url('./HelveticaNowText-Medium.ttf') format('truetype'),
    url('./HelveticaNowText-Medium.svg#HelveticaNowText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
